import { CompanyId } from 'app/shared/domain/value-objects/CompanyId';
import { Integer } from 'app/shared/domain/value-objects/Integer';
import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { UserClientRef } from 'app/shared/domain/value-objects/UserClientRef';
import { OrderLineResponse } from 'app/shared/infrastructure/tencer-api/dto/responses/OrderLineResponse';
import { OrderResponse, StatusDTO } from 'app/shared/infrastructure/tencer-api/dto/responses/OrderResponse';
import Order from '../../../../model/Order';
import { ShippingAddressId } from '../../../../model/ShippingAddressId';
import { Discount } from '../../../../model/value-objects/Discount';
import { OrderLineIdx } from '../../../../model/value-objects/OrderLineIdx';
import { PackageTrackingNo } from '../../../../model/value-objects/PackageTrackingNo';
import { Price } from '../../../../model/value-objects/Price';
import ProductQuantity from '../../../../model/value-objects/ProductQuantity';
import { ShippingAgentCode } from '../../../../model/value-objects/ShippingAgentCode';
import { ToneId } from '../../../../model/value-objects/ToneId';
import { Weight } from '../../../../model/value-objects/Weight';
import { Year } from '../../../../model/value-objects/Year';
import { ProductId } from '../../../../../products/domain/value-object/ProductId';
import OrderLine from '../../../../model/OrderLine';
import { OrderLineStatus } from '../../../../model/value-objects/OrderLineStatus';
import { OrderFileMapper } from './OrderFileMapper';
import { ORDER_STATUS_TYPE, Status } from '../../../../model/value-objects/Status';
import { ShippingAddressMapper } from './ShippingAddressMapper';
import { TransportMapper } from './transport-mapper';
import { DiscountResponse } from '../../../../../shared/infrastructure/tencer-api/dto/responses/DiscountResponse';

export class OrderMapper {

    private static statusToDomain(status: StatusDTO): ORDER_STATUS_TYPE {
        switch (status) {
            case StatusDTO.ORDERED:
                return ORDER_STATUS_TYPE.ORDERED;
            case StatusDTO.IN_PREPARATION:
                return ORDER_STATUS_TYPE.IN_PREPARATION;
            case StatusDTO.READY:
                return ORDER_STATUS_TYPE.READY;
            case StatusDTO.CHARGED:
                return ORDER_STATUS_TYPE.CHARGED;
            case StatusDTO.INVOICED:
                return ORDER_STATUS_TYPE.INVOICED;
            case StatusDTO.RELEASED:
                return ORDER_STATUS_TYPE.RELEASED;
            case StatusDTO.OPEN:
                return ORDER_STATUS_TYPE.OPEN;
            default:
                return ORDER_STATUS_TYPE.UNKNOWN;
        }
    }
    static fromResponse(response: OrderResponse): Order {
        return new Order(
            new OrderId(response.id),
            new CompanyId(response.company),
            new Year(response.year),
            response.shipping ? new ShippingAddressId(response.shipping) : null,
            response.lines.map((line: OrderLineResponse) => this._orderLineToDomain(line)),
            response.order_date ? new Date(response.order_date) : null,
            response.user_client_ref ? new UserClientRef(response.user_client_ref) : null,
            OrderFileMapper.fromOrderFilesResponse(response.files ?? []),
            new Weight(response.weight_gross ?? 0),
            new Weight(response.weight_net ?? 0),
            new Price(response.gross ?? 0),
            new Price(response.net ?? 0),
            response.shipping_agent_code ? new ShippingAgentCode(response.shipping_agent_code) : null,
            response.package_tracking_no ? new PackageTrackingNo(response.package_tracking_no) : null,
            response.status && response.status !== null ?
                new Status(this.statusToDomain(response.status))
                : null,
            response.shipping_address ? ShippingAddressMapper.fromResponse(response.shipping_address) : null,
            response.order_shipment_line ? TransportMapper.fromResponse(response.order_shipment_line) : null,
            response.pallet_handling_fee_line ? this._orderLineToDomain(response.pallet_handling_fee_line) : null,
            response.discounts.map(
                (discount: DiscountResponse) => new Discount(discount.percent, discount.description),
            ),
        );
    }

    // TODO: extract into a separate mapper
    private static _orderLineToDomain(line: OrderLineResponse): OrderLine {
        return new OrderLine(
            new OrderLineIdx(line.idx),
            new Weight(line.weight_gross),
            new Price(line.price),
            new ProductId(line.product_id),
            new ProductQuantity(
                line.quantity.amount,
                line.quantity.unit,
            ),
            line.description,
            new ToneId(line.tone),
            new Integer(line.pallets ?? 0),
            new Integer(line.boxes_total ?? 0),
            new Integer(line.boxes ?? 0),
            new Weight(line.weight_net ?? 0),
            new Discount(line.discount_special ?? 0),
            new Discount(line.discount_special2 ?? 0),
            line.es_pico,
            new Price(line.net),
            new Price(line.gross),
            line.delivery_date,
            new OrderLineStatus(line.status),
            line.warehouse_location ? {
                code: line.warehouse_location.code,
                name: line.warehouse_location.name,
            } : null,
        );
    }

}
